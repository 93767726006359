// @flow
import * as React from "react";
import { Row, Col, OverlayTrigger, Tooltip, Table, Form, Button } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import { useState, useEffect, FC } from "react";
import SelectSearch from "../../components/SelectSearch";
import { MessageType } from "../../core/config/message-type";
import { CompetitorModel } from "../../core/model/competitor";
import { CompetitorRepository } from "../../core/repositories/competitor.repository";
import { competitorTypeList, convertArrayToCsvText, getNameFilter, matchingRatingList, matchingStatusList } from "../../core/utils/functions";
import { FilterHeader } from "../../components/FilterHeader";
import { SearchInput } from "../../components/SearchInput";
import { FaInfoCircle } from "react-icons/fa";
import { FilterNewProductMatching, NewProductMatchingRepository } from "../../core/repositories/new_product_matching.repository";
import { ProductCategoryRepository } from "../../core/repositories/product_category.repository";
import { FilterBasePriceCatalog } from "../../core/repositories/base_price_catalog.repository";
import { ProductCategoryModel } from "../../core/model/product_category";
import { ProductModel } from "../../core/model/product";
import { ProductRepository } from "../../core/repositories/product.repository";

interface Props {
  search: FilterNewProductMatching;
  onChangeFilter: (search: FilterNewProductMatching) => void;
  onSearch: (search: FilterNewProductMatching) => void;
  onDownload: () => void;
}

export const Filter: React.FC<Props> = (props) => {
  const initialCbOptions = [
    { id: "A", label: "A", checked: true },
    { id: "B", label: "B", checked: false },
    { id: "C", label: "C", checked: false },
  ];
  const [cbOptions, setCbOptions] = useState(initialCbOptions);

  const { toastShow } = useToast();
  const [competitorList, setCompetitorList] = useState<CompetitorModel[]>([]);
  const [categoryList, setCategoryList] = useState<ProductCategoryModel[]>([]);
  const [productList, setProductList] = useState<ProductModel[]>([]);

  const [loadingRoutine, setLoadingRoutine] = useState(false);

  useEffect(() => {
    try {
      new NewProductMatchingRepository().getCompetitor().then((res) => {
        setCompetitorList(res);
      });
    } catch (error) {
      toastShow({
        title: "Erro!",
        subTitle: MessageType.ERROR_TRY,
        type: "error",
      });
    }
    getCategoryList(props.search);
    getProductList(props.search);

    const selectedValues = initialCbOptions
      .filter((option) => option.checked)
      .map((option) => option.id)
      .join(", ");

    props.search.curve = selectedValues;
    props.onChangeFilter(props.search);
  }, []);

  function getCategoryList(filter: FilterNewProductMatching) {
    new ProductCategoryRepository()
      .getFilter(filter)
      .then((res) => {
        setCategoryList(res);
      })
      .catch((error) => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });
  }

  function getProductList(filter: FilterNewProductMatching) {
    new ProductRepository()
      .getFilter(filter, "_post")
      .then((res) => {
        setProductList(res);
      })
      .catch((error) => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });
  }

  const handleCheckboxChange = (id: string) => {
    const updatedOptions = cbOptions.map((option) => (option.id === id ? { ...option, checked: !option.checked } : option));

    // Verifica se pelo menos uma opção está marcada
    const atLeastOneChecked = updatedOptions.some((option) => option.checked);

    if (atLeastOneChecked) {
      setCbOptions(updatedOptions);

      const selectedValues = updatedOptions
        .filter((option) => option.checked)
        .map((option) => option.id)
        .join(", ");

      props.search.curve = selectedValues;
      props.onChangeFilter(props.search);
    }
    // else {
    //   alert("Pelo menos uma checkbox precisa estar selecionada!");
    // }
  };

  function rotina() {
    setLoadingRoutine(true);
    new NewProductMatchingRepository()
      .routine()
      .then((req) => {
        toastShow({
          title: "Sucesso!",
          subTitle: "Dados apagados com sucesso!",
          type: "success",
        });
      })
      .catch(() => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      })
      .finally(() => {
        setLoadingRoutine(false);
      });
  }

  return (
    <div className="stick">
      <FilterHeader
        title={
          <label className="NewProductMatching">
            Product Matching &nbsp;
            <OverlayTrigger
              placement="bottom"
              //show={true}

              overlay={
                <Tooltip className="width-600">
                  Essa tela tem como objetivo validar os casamentos de produtos entre a Laredo e os concorrentes, realizados pelo modelo de Product Matching.
                  <Table className="mt-2" striped bordered hover variant="dark">
                    <tbody>
                      <tr>
                        <td>Concorrente ID</td>
                        <td>Número do CNPJ do concorrente criptografado</td>
                      </tr>
                      <tr>
                        <td>Produto Concorrente</td>
                        <td>Código e descrição do produto concorrente</td>
                      </tr>
                      <tr>
                        <td>Curva ABC</td>
                        <td>Classificação do produto na Curva ABC</td>
                      </tr>
                      <tr>
                        <td>Produto Laredo</td>
                        <td>Código e descrição do produto Laredo</td>
                      </tr>
                      <tr>
                        <td>Usuário</td>
                        <td>Qual foi a usuário que realizou o último casamento</td>
                      </tr>
                      <tr>
                        <td>Última Atualização</td>
                        <td>Quando foi a última atualização do usuário nesse casamento</td>
                      </tr>
                      <tr>
                        <td>Ações do usúario</td>
                        <td>
                          Confirmar: Confirmar o casamento correto
                          <br />
                          Reverter: Desfazer a ação tomada sobre o casamento
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Tooltip>
              }
            >
              <label className="click text-primary vertical-50 margin-top--1">
                <FaInfoCircle />
              </label>
            </OverlayTrigger>
          </label>
        }
        clearFilter={() => {
          var selects = document.getElementsByTagName("select");
          for (var i = 0; i < selects.length; i++) {
            selects[i].value = "";
          }
          props.onChangeFilter(new FilterNewProductMatching());
        }}
        reload={() => {
          props.onChangeFilter(props.search);
        }}
      >
        <>
          <Row>
            <Col md={3}>
              <SelectSearch
                name="Concorrente: ID"
                selectId="description"
                selectName="description"
                className="w-100"
                multiple={true}
                value={props.search.competitor_id}
                options={competitorList}
                onChange={(value) => {
                  if (Array.isArray(value)) props.search.competitor_id = convertArrayToCsvText(value);
                  else props.search.competitor_id = value.toString();
                  props.onChangeFilter(props.search);
                }}
              />
            </Col>

            <Col md={3}>
              <SearchInput
                description="Concorrente: Cód. Produto"
                value={props.search.competitor_product_id}
                onChange={(event) => {
                  props.search.competitor_product_id = event;
                  props.onChangeFilter(props.search);
                }}
              />
            </Col>

            <Col md={3}>
              <SearchInput
                description="Concorrente: Descrição"
                value={props.search.competitor_product_description}
                onChange={(event) => {
                  props.search.competitor_product_description = event;
                  props.onChangeFilter(props.search);
                }}
              />
            </Col>

            <Col md={3}>
              <SelectSearch
                name="Status"
                selectId="id"
                selectName="description"
                dontShowSearch={true}
                required={true}
                value={props.search.match_status}
                options={matchingStatusList}
                onChange={(value) => {
                  props.search.match_status = value;
                  props.search.match_status_description = getNameFilter(matchingStatusList, value, "id", "description");

                  props.search.product_code = "";
                  props.search.category_code = "";

                  props.onChangeFilter(props.search);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <SelectSearch
                name="Laredo: Categoria"
                disabled={props.search.match_status != 1}
                selectId="id"
                selectName="description"
                className="w-100"
                multiple={true}
                value={props.search.category_code}
                options={categoryList}
                onChange={(value) => {
                  value = value.toString();
                  props.search.category_code = value;
                  props.search.product_code = "";
                  props.onChangeFilter(props.search);

                  getProductList(props.search);
                }}
              />
            </Col>

            <Col md={3}>
              <SelectSearch
                name="Laredo: Produto"
                disabled={props.search.match_status != 1}
                selectId="id"
                selectName="description_show"
                className="w-100"
                multiple={true}
                value={props.search.product_code}
                options={productList}
                onChange={(value) => {
                  value = value.toString();

                  props.search.product_code = value;
                  props.onChangeFilter(props.search);
                }}
              />
            </Col>

            <Col>
              <Form.Group className={"w-100"}>
                <Form.Label className="font-size-14 relative">Curva ABC</Form.Label>
                <div className="position-relative">
                  {cbOptions.map((option) => (
                    <Form.Check
                      inline
                      type="checkbox"
                      key={option.id}
                      id={"checkbox-" + option.id}
                      name="group1"
                      aria-label={option.label}
                      aria-checked={option.checked}
                      label={option.label}
                      checked={option.checked}
                      onChange={() => handleCheckboxChange(option.id)}
                    />
                  ))}
                </div>
              </Form.Group>
            </Col>
            <Col></Col>
            <Col md={3}>
              <div className="d-flex justify-content-end margin-top-32">
                {process.env.REACT_APP_BASE_BACK && (
                  <div>
                    <Button className="ms-1 text-white" variant="danger" onClick={rotina} disabled={loadingRoutine}>
                      Atualizar Dados
                    </Button>
                  </div>
                )}

                <Button
                  className="ms-1"
                  variant="success"
                  // disabled={!props.filtro.year_week}
                  onClick={() => {
                    props.onDownload();
                  }}
                >
                  Extrair
                </Button>

                <Button
                  className="ms-1"
                  variant="primary"
                  // disabled={!props.filtro.year_week}
                  onClick={() => {
                    props.onSearch(props.search);
                  }}
                >
                  Buscar
                </Button>
              </div>
            </Col>
          </Row>
        </>
      </FilterHeader>
    </div>
  );
};
