import * as React from "react";
import { Badge, Button, OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import TablePagination from "../../components/TablePagination";
import TableRow from "../../components/TableRow";
import { FaCheck, FaInfoCircle, FaUndo, FaEdit, FaTrash } from "react-icons/fa";
import { ProductModel } from "../../core/model/product";
import { useToast } from "../../core/contexts/toast";
import { useAuth } from "../../core/contexts/auth";
import moment from "moment";
import { IoClose } from "react-icons/io5";
import { competitorTypeList, get_matching_rating_description } from "../../core/utils/functions";
import { FormatType } from "../../core/config/format-type";
import { FilterNewProductMatching, NewProductMatchingRepository } from "../../core/repositories/new_product_matching.repository";
import { NewProductMatchingModel } from "../../core/model/new_product_matching";
import { ModalNewProductMatching } from "./modal";
import SelectSearch from "../../components/SelectSearch";

interface Props {
  search: FilterNewProductMatching;
  data: NewProductMatchingModel[];
  // lastUpdate?: Date;
  productList: ProductModel[];
  onChangeSort: (search: FilterNewProductMatching, loading: boolean) => void;
  onChangeData: (data: NewProductMatchingModel, index: number) => void;
  onRemoveData: (index: number) => void;
}

export const TableNewProductMatching: React.FC<Props> = (props) => {
  const [matchingValid, setMatchingValid] = React.useState<number>(0);
  const [matchingNotValid, setMatchingNotValid] = React.useState<number>(0);
  const [selectIndex, setSelectIndex] = React.useState(-1);

  // const [lastUpdateIndex, setLastUpdateIndex] = React.useState(-1);
  const { toastShow } = useToast();
  const { user } = useAuth();

  const _newProductMatchingRepository = new NewProductMatchingRepository();

  React.useEffect(() => {
    if (props.data.length > 0) {
      setMatchingValid(props.data[0].total_validated);
      props.data[0].total_not_validated = props.data[0].total_records - props.data[0].total_validated;
      setMatchingNotValid(props.data[0].total_not_validated);
    }
  }, [props.data]);

  function saveItem(data: NewProductMatchingModel, indexAux: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data && user) {
        data.loading = true;
        data.user_id = user.id;
        data.user_full_name = user.name;
        data.date_update_by_user = new Date();
        props.onChangeData(data, indexAux);

        console.log(data);

        _newProductMatchingRepository
          .add(data)
          .then((res) => {
            data.loading = false;
            // data.id = res.data.id;
            data.match_status = 1;
            props.onChangeData(data, indexAux);
            props.data[0].total_validated = matchingValid + 1;
            props.data[0].total_not_validated = matchingNotValid - 1;
            resolve(res);
          })
          .catch((_err) => {
            data.loading = false;
            data.user_id = -1;
            data.user_full_name = "";
            data.date_update_by_user = undefined;
            props.onChangeData(data, indexAux);

            // props.onChangeData({ ...data }, selectIndex);
            toastShow({
              title: "Erro!",
              subTitle: `Erro no ${data.competitor_product_description}!`,
              type: "error",
            });
            reject();
          });
      } else {
        reject();
      }
    });
  }

  function correctItem(data: NewProductMatchingModel, indexAux: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data && user) {
        data.loading = true;
        data.user_id = user.id;
        data.user_full_name = user.name;
        data.date_update_by_user = new Date();
        props.onChangeData(data, indexAux);

        console.log(data);

        _newProductMatchingRepository
          .correct(data)
          .then((res) => {
            data.loading = false;
            // data.id = res.data.id;
            data.match_status = 5;
            data.product_descriptions_laredo = "";
            data.product_ids_laredo = "";
            data.inactive_products = "";
            props.onChangeData(data, indexAux);

            props.data[0].total_validated = matchingValid + 1;
            props.data[0].total_not_validated = matchingNotValid - 1;
            resolve(res);
          })
          .catch((_err) => {
            data.loading = false;
            data.user_id = -1;
            data.user_full_name = "";
            data.date_update_by_user = undefined;
            props.onChangeData(data, indexAux);

            // props.onChangeData({ ...data }, selectIndex);
            toastShow({
              title: "Erro!",
              subTitle: `Erro no ${data.competitor_product_description}!`,
              type: "error",
            });
            reject();
          });
      } else {
        reject();
      }
    });
  }

  function reset(data: NewProductMatchingModel, indexAux: number) {
    return new Promise((resolve, reject) => {
      if (data && user) {
        data.loading = true;
        data.user_id = user.id;
        data.user_full_name = user.name;
        data.date_update_by_user = new Date();
        props.onChangeData(data, indexAux);

        //   console.log(data);
        // const indexAux = index * 1;
        // data.loading = true;
        // props.onChangeData(data, indexAux);
        // // setLastUpdateIndex(indexAux);
        _newProductMatchingRepository
          .reset(data)
          .then((res) => {
            // data.match_status = res.match_status;
            // data.product_id = res.product_id;
            // data.product_description = res.product_description;
            // data.date_update_by_user = res.date_update_by_user;

            // data.loading = false;
            // props.data[0].total_validated = data.match_status != res.match_status ? matchingValid : matchingValid > 0 ? matchingValid - 1 : matchingValid;

            // props.onChangeData(data, indexAux);

            data.loading = false;
            // data.id = res.data.id;
            data.match_status = 0;
            props.onChangeData(data, indexAux);
            props.data[0].total_validated = matchingValid - 1;
            props.data[0].total_not_validated = matchingNotValid + 1;
            resolve(res);

            // getMatchingValid();
            // props.onChangeSort(props.search, true);
            toastShow({
              title: "Sucesso!",
              subTitle: "Dados resetados com sucesso!",
              type: "success",
            });
          })
          .catch((_err) => {
            data.loading = false;
            props.onChangeData(data, indexAux);
            toastShow({
              title: "Erro!",
              subTitle: "Erro ao resetar dados!",
              type: "error",
            });
          });
      } else {
        reject();
      }
    });
  }

  function changeSort(value: string, prop: string) {
    const aux: any = {};
    aux[prop] = value;
    props.search.sort = aux;
    props.onChangeSort(props.search, true);
  }

  function saveData(data: NewProductMatchingModel, indexIntro: number) {
    if (data.product_id != null && data.product_id != "") {
      saveItem(data, indexIntro * 1)
        .then((res) => {
          //props.data[0].total_validated = matchingValid + 1;
          toastShow({
            title: "Sucesso!",
            subTitle: `[${data.competitor_product_id}] ${data.competitor_product_description} modificado com sucesso!`,
            type: "success",
          });
        })
        .catch((res) => {
          data.match_status = 0;
        });
    } else {
      toastShow({
        title: "Erro!",
        subTitle: `Algum produto deve estar selecionado para realizar o matching!`,
        type: "error",
      });
    }
  }

  function correctErro(data: NewProductMatchingModel, indexIntro: number) {
    if (data.product_id != null && data.product_id != "") {
      correctItem(data, indexIntro * 1)
        .then((res) => {
          //props.data[0].total_validated = matchingValid + 1;
          toastShow({
            title: "Sucesso!",
            subTitle: `[${data.competitor_product_id}] ${data.competitor_product_description} modificado com sucesso!`,
            type: "success",
          });
        })
        .catch((res) => {
          data.match_status = 2;
        });
    } else {
      toastShow({
        title: "Erro!",
        subTitle: `Algum produto deve estar selecionado para realizar o matching!`,
        type: "error",
      });
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <OverlayTrigger
          placement="bottom"
          //show={true}

          overlay={<Tooltip>Os valores de casamentos (matching) confirmados e não confirmados refletem os filtros aplicados.</Tooltip>}
        >
          <label className="click text-primary vertical-50 margin-top--2">
            <FaInfoCircle />
          </label>
        </OverlayTrigger>
        <div className="font-size-15 margin-left-18">
          Matching Confirmados:&nbsp;
          {matchingValid == null ? <Spinner size="sm" animation="border" role="status" /> : <b>{matchingValid}</b>}
        </div>
        <div className="font-size-15">
          Matching Não Confirmados:&nbsp;
          {matchingNotValid == null ? <Spinner size="sm" animation="border" role="status" /> : <b>{matchingNotValid}</b>}
        </div>
        {/* <div className="font-size-15 min-width-290">
          Última atualização do modelo:&nbsp;
          {props.lastUpdate === undefined ? (
            <Spinner size="sm" animation="border" role="status" />
          ) : (
            props.lastUpdate && (
              <label className="click absolute margin-top--1">
                <b>{moment(props.lastUpdate).format(FormatType.MOMENT_FORMAT)}</b>
              </label>
            )
          )}
        </div> */}
      </div>

      <div className="table-height">
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>
              <TableRow sort={props.search.sort.competitor_id} onSort={(ev: any) => changeSort(ev, "competitor_id")}>
                Concorrente ID
              </TableRow>

              <TableRow
                classNameTh="max-width-600"
                sort={props.search.sort.competitor_product_id}
                onSort={(ev: any) => changeSort(ev, "competitor_product_id")}
              >
                Produto Concorrente
              </TableRow>

              <TableRow sort={props.search.sort.curve} onSort={(ev: any) => changeSort(ev, "curve")}>
                Curva ABC
              </TableRow>

              {props.data.length > 0 && props.data[0].match_status > 1 ? (
                <>
                  <TableRow>Produto Laredo</TableRow>

                  <TableRow>Produto Laredo com Erros</TableRow>

                  <TableRow>Ações</TableRow>
                </>
              ) : (
                <>
                  <TableRow sort={props.search.sort.product_id} onSort={(ev: any) => changeSort(ev, "product_id")}>
                    Produto Laredo
                  </TableRow>

                  <TableRow sort={props.search.sort.user_full_name} onSort={(ev: any) => changeSort(ev, "user_full_name")}>
                    Usuário
                  </TableRow>

                  <TableRow sort={props.search.sort.date_update_by_user} onSort={(ev: any) => changeSort(ev, "date_update_by_user")}>
                    Última <br /> Atualização
                  </TableRow>

                  <TableRow>Ações</TableRow>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {props.data.length === 0 && (
              <tr className="table-row-header text-center">
                <td colSpan={8}> Nenhum registro encontrado! </td>
              </tr>
            )}
            {props.data.map((_, index) => (
              <tr
                key={`TableNewProductMatching_${index}`}
                className={_.match_status == 2 ? "bg-lightred" : _.match_status == 1 || _.match_status == 5 ? "bg-ligthgreen" : ""}
              >
                {/* Concorrente ID */}
                <td className="text-center">{_.competitor_id}</td>

                {/* Produto Concorrente */}
                <td>
                  <b>{"[" + _.competitor_product_id + "]"}</b> {_.competitor_product_description}
                </td>

                {/* Curva */}
                <td className="text-center">
                  <b>{_.curve ? _.curve : "-"}</b>
                </td>

                {/* Produto Laredo */}
                <td>
                  <SelectSearch
                    modalName="Produto"
                    selectId="id"
                    selectName="description_show"
                    id={"Produto_" + _.id}
                    multiple={false}
                    // required={true}
                    dontShowSearch={true}
                    value={_.product_id}
                    disabled={_.match_status == 1 || _.match_status == 5}
                    options={props.productList}
                    onChange={(value) => {
                      value = value.toString();
                      console.log("Select Product: ", value);
                      _.product_id = value;
                      props.onChangeData(_, index);
                    }}
                  />
                </td>

                {_.match_status > 1 ? (
                  <>
                    {/* Produto Laredo com Erros */}
                    <td>
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip className="width-400">
                            <Table className="mt-2" striped bordered hover variant="dark">
                              <tbody>
                                {_.product_ids_laredo != "" &&
                                  _.product_ids_laredo.split(", ").map((id, index) => {
                                    const inativo = _.inactive_products?.split(", ").includes(id);

                                    return (
                                      <tr key={index}>
                                        <td>
                                          <b>[{id}]</b>
                                        </td>
                                        <td>
                                          {_.product_descriptions_laredo.split(", ")[index]}
                                          {inativo && " (INATIVO)"}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </Table>
                          </Tooltip>
                        }
                      >
                        <div>
                          {_.product_ids_laredo != "" &&
                            _.product_ids_laredo.split(", ").map((id, index) => {
                              const inativo = _.inactive_products?.split(", ").includes(id);
                              return (
                                <div key={index} className={inativo ? "text-danger" : ""}>
                                  <b>[{id}]</b>
                                </div>
                              );
                            })}
                        </div>

                        {/* <div>
                          {[...Array.from(new Set(_.product_ids_laredo.split(", ").map((id) => id.trim())))].map((id, index) => {
                            const inativo = _.inactive_products?.split(", ").includes(id);
                            return (
                              <div key={index} className={inativo ? "text-danger" : ""}>
                                <b>[{id}]</b>
                              </div>
                            );
                          })}
                        </div> */}
                        {/* <label className="click text-primary margin-top--1 margin-left-80">
                        <FaInfoCircle />
                      </label> */}
                      </OverlayTrigger>
                    </td>

                    {/* Ações */}
                    <td className="text-center">
                      <Button
                        title="Confirmar"
                        size="sm"
                        className="mr-1 "
                        disabled={_.match_status == 5 || _.product_id == null || _.product_id == ""}
                        variant="success"
                        onClick={() => {
                          correctErro(_, index);
                        }}
                      >
                        <FaCheck />
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    {/* Usuário */}
                    <td className="text-center relative">{_.loading ? <Spinner animation="grow" size="sm" /> : <label>{_.user_full_name}</label>}</td>

                    {/* Última Atualização */}
                    <td className="text-center relative">
                      {_.loading ? (
                        <Spinner animation="grow" size="sm" />
                      ) : (
                        _.date_update_by_user && <label>{moment.utc(_.date_update_by_user).local().format("DD/MM/YYYY HH:mm")}</label>
                      )}
                    </td>

                    {/* Ações */}
                    <td className="text-center">
                      <Button
                        title="Confirmar"
                        size="sm"
                        className="mr-1 "
                        disabled={_.match_status != 0 || _.product_id == null || _.product_id == ""}
                        variant={_.match_status == 0 ? "success" : "light"}
                        onClick={() => {
                          saveData(_, index);
                        }}
                      >
                        <FaCheck />
                      </Button>

                      <Button
                        size="sm"
                        title="Reverter"
                        className="mr-1 "
                        variant="outline-secondary"
                        disabled={_.match_status == 0}
                        onClick={() => {
                          reset(_, index);
                        }}
                      >
                        <FaUndo />
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div>
        <TablePagination
          onChangePage={(value) => {
            props.search.page = value;
            props.onChangeSort(props.search, true);
          }}
          currentPage={props.search.page}
          pageSize={props.search.pageSize}
          count={props.search.total}
        />
      </div>

      {/* <ModalConfirm
				title={`Confirma se deseja rejeitar o produto  ?`}
				subTitle={`Você deseja rejeitar do produto laredo ${selectDelete?.product_description}?`}
				show={!!selectDelete}
				onHide={() => {
					setSelectDelete(undefined);
				}}
				confirm={reject}
			/> */}
    </>
  );
};
