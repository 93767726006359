import * as React from "react";
import { useEffect, useRef } from "react";
import { Token, Typeahead } from "react-bootstrap-typeahead";
import Form from "react-bootstrap/esm/Form";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaInfoCircle, FaSearch } from "react-icons/fa";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./index.scss";
import { useModalSelect } from "../../core/contexts/modalSelectContext";
import { BaseModel } from "../../core/model/base-model";

interface Props {
  disabled?: boolean;
  options: any[];
  id?: string;
  name?: string;
  modalName?: string;
  className?: string;
  scroll?: boolean;
  value?: any;
  sort?: boolean;
  selectName: string;
  selectId: string;
  multiple?: boolean;
  required?: boolean;
  showCheckbox?: boolean;
  checked?: boolean;
  dontShowSearch?: boolean;
  onChangeCheckbox?: (value: boolean) => void;
  onChange?: (value: any) => void;
  clear?: () => void;
}

const SelectSearch: React.FC<Props> = (props) => {
  const ref = useRef();
  const [selected, setSelected] = React.useState<any>([]);
  const { mostrarModal } = useModalSelect();

  useEffect(() => {
    if (props && props.value && (ref as any).current && props.options.length > 0) {
      setSelected(props.options.filter((x) => x[props.selectId || "value"] == props.value));
    }
  }, []);

  useEffect(() => {}, [props.name]);

  useEffect(() => {
    if ((ref as any).current) {
      if (!props.value || props.value.length == 0) {
        (ref as any).current.clear();
        setSelected([]);
      } else if (props.value && props.value.length > 0 && typeof props.value != "string") {
        setSelected(props.options.filter((x) => props.value.find((y: any) => y == x[props.selectId || "value"])));
      }
    }
  }, [props.value]);

  useEffect(() => {
    if ((ref as any).current) {
      if (!props.value || props.value.length == 0 || props.options.length == 0) {
        (ref as any).current.clear();
        setSelected([]);
      } else if (props.value && props.value.length > 0 && typeof props.value != "string") {
        setSelected(props.options.filter((x) => props.value.find((y: any) => y == x[props.selectId || "value"])));
      } else if (props.value && props.options.length > 0) {
        setSelected(props.options.filter((x) => props.value.split(",").filter((y: any) => y == x[props.selectId || "value"]).length > 0));
      }
    }
  }, [props.options]);

  function handleSelect(data: any) {
    setSelected(data);
    if (!props.multiple && props.required && props.onChange) {
      props.onChange(data);
    } else {
      if (!props.required && props.onChange) {
        if (data && data.length) {
          props.onChange(data.length == 1 ? data[0][props.selectId || "value"] : data.map((x: any) => x[props.selectId || "value"]));
        } else {
          props.onChange("");
        }
      }
    }
  }
  async function handleSelectModal() {
    const selectedData = await mostrarModal({
      titulo: props.name || props.modalName || "",
      multiple: props.multiple,
      itens: props.options.map((x) => ({
        id: x[props.selectId],
        description: x[props.selectName],
        total_records: 0,
      })),
      selecionados: typeof selected === "object" ? selected.map((x: any) => x[props.selectId]) : [selected],
    });

    if (props.multiple) {
      handleSelect(
        selectedData.map((x) => {
          return {
            [props.selectId]: x.id,
            [props.selectName]: x.description,
          };
        })
      );
    } else {
      handleSelect(selectedData[0].id);
    }
  }

  return (
    <Form.Group className={"SelectSearch w-100 " + (props.name ? "mb-3 mr-3 " : "") + (props.className || "")}>
      {props.name && (
        <Form.Label className="font-size-14 relative">
          {props.showCheckbox && (
            <Form.Check
              inline
              checked={props.checked}
              onChange={(e) => {
                props.onChangeCheckbox && props.onChangeCheckbox(e.target.checked);
              }}
              type="checkbox"
            />
          )}
          {props.name} &nbsp;
          {props.multiple && (
            <OverlayTrigger overlay={<Tooltip>Comece a digitar ou clique para adicionar itens de seleção múltipla</Tooltip>}>
              <label className="click text-primary vertical-50 margin-top--1">
                <FaInfoCircle />
              </label>
            </OverlayTrigger>
          )}
        </Form.Label>
      )}
      <div className="position-relative">
        {props.required ? (
          <Form.Select
            disabled={props.disabled}
            value={props.value}
            placeholder="Selecionar"
            onChange={(event) => {
              handleSelect(event.target.value);
            }}
          >
            {props.options && props.options.length ? (
              props.options.map((option, index) => (
                <option key={index + (props.name || props.modalName || "")} value={option[props.selectId]}>
                  {option[props.selectName]}
                </option>
              ))
            ) : (
              <option value="">Carregando...</option>
            )}
          </Form.Select>
        ) : (
          <Typeahead
            className={"custom-typeahead " + (props.scroll ? "scroll-select" : "")}
            disabled={props.disabled}
            ref={ref as any}
            clearButton
            id={props.id || props.name}
            options={props.options}
            onChange={handleSelect}
            multiple={props.multiple && !props.disabled}
            labelKey={props.selectName || props.options[0].keys[0]}
            selected={selected}
            placeholder="Selecionar"
            emptyLabel="Nenhum resultado encontrado"
            paginationText="Mais resultados..."
          />
        )}
        {!props.dontShowSearch && (
          <Button className="rounded-circle btn-search" size="sm" variant="outline-primary" onClick={handleSelectModal}>
            <FaSearch />
          </Button>
        )}
      </div>
    </Form.Group>
  );
};
export default SelectSearch;
