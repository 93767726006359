import React from "react";
import { ProductMatching } from "../pages/ProductMatching";
import { CustomerSegmentation } from "../pages/CustomerSegmentation";
import Page404 from "../pages/Page404";
import UserManagement from "../pages/UserManagement";
import { MatchingValidations } from "../pages/MatchingValidations";
import ChartActiveCluster from "../pages/ChartActiveCluster";
import ChartCohort from "../pages/ChartCohort";
import CNPJHash from "../pages/CNPJHash";
// import BasePriceCatalog from "../pages/BasePriceCatalog/index";
import { Routes, Route, Navigate } from "react-router-dom";
import MainLayout from "../components/Layout";
import RequireAuth from "./require_auth";
import DemandForecast from "../pages/DemandForecast";
import { DemandVariability } from "../pages/DemandVariability";
import { LogApi } from "../pages/LogApi";
import BasePrice from "../pages/BasePrice";
import { BasePriceCatalogProvider } from "../core/contexts/basePriceCatalogContext";
import { LoginSuccess } from "../pages/LoginSuccess";
import { NewProductMatching } from "../pages/NewProductMatching";

const AppRoutes: React.FC = () => (
  <Routes>
    {/* <Route path="/loginsuccess" element={LoginSuccess} /> */}

    <Route element={<MainLayout />}>
      <Route
        path="base-price"
        element={
          <BasePriceCatalogProvider>
            <BasePrice />
          </BasePriceCatalogProvider>
        }
      />

      <Route element={<RequireAuth allowedRoles={[1]} />}>
        <Route path="/user-management" element={<UserManagement />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={[1, 2]} />}>
        <Route path="/product-matching" element={<NewProductMatching />} />
      </Route>

      <Route element={<RequireAuth allowedRoles={process.env.REACT_APP_USER_DEV?.split(",") || []} />}>
        <Route path="/product-matching" element={<ProductMatching />} />
        <Route path="/matching-validations" element={<MatchingValidations />} />
        <Route path="/customer-segmentation" element={<CustomerSegmentation />} />
        <Route path="/cnpj-hash" element={<CNPJHash />} />
        <Route path="/chart-active-cluster" element={<ChartActiveCluster />} />
        <Route path="/chart-cohort-analysis" element={<ChartCohort />} />
        <Route path="/demand-forecast" element={<DemandForecast />} />
        <Route path="/demand-variability" element={<DemandVariability />} />
        <Route path="/log-api" element={<LogApi />} />
      </Route>
      <Route
        path="/"
        element={
          <BasePriceCatalogProvider>
            <BasePrice />
          </BasePriceCatalogProvider>
        }
      />
      <Route path="*" element={<Navigate to="/page-404" replace />} />
      <Route path="/page-404" element={<Page404 />} />
    </Route>
  </Routes>
);

export default AppRoutes;
