import { BaseModel } from "./base-model";

export class BasePriceCatalogEditModel extends BaseModel {
  branch_code: number = 0;
  department_code: number = 0;
  section_code: number = 0;
  category_code: number = 0;
  subcategory_code: string = "";
  mesorregion_code: number = 0;
  product_code: number = 0;
  year_week: string = "";

  price_edit?: number;
  is_automated: boolean = false;
  is_accepted: boolean = false;
  user_id?: number;
  branch_type?: string;
  num_level?: number;
  subcategory_priority: string = "";
  filter_mesorregion: string = "";
  filter_product: string = "";
}
