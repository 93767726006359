import { CollecionName } from "../config/collection-name";
import { NewProductMatchingModel } from "../model/new_product_matching";
import { Repository } from "./repository";

export class FilterNewProductMatching {
  competitor_id!: string;
  competitor_product_id!: string;
  competitor_product_description!: string;
  match_rating!: string;
  match_rating_description = "";
  competitor_issuer_type!: string;
  competitor_issuer_type_description!: string;
  match_status = 0;
  match_status_description = "";
  competitor_ncm!: string;
  product_id?: string;
  product_description?: string;
  is_similar?: boolean;
  curve?: string;

  category_code?: string;
  product_code?: string;

  produtoConcorrente?: string;

  sort: Partial<NewProductMatchingModel> = {};

  page = 1;
  pageSize = 20;
  total = 0;
  constructor() {}
}

export class NewProductMatchingRepository extends Repository<NewProductMatchingModel> {
  constructor() {
    super(CollecionName.NEW_PRODUCT_MATCHING);
  }

  reset(data: NewProductMatchingModel) {
    return this.api.post(`${this.collectionName}_reset`, data).then((res) => {
      return res.data;
    });
  }
  getLastUpdate() {
    return this.api.get(`${this.collectionName}_last_update`).then((res) => {
      return res.data[0].date_creation;
    });
  }

  routine(): Promise<Boolean> {
    return this.api.post(this.collectionName + "_routine").then((res: any) => {
      return res.data.success;
    });
  }

  getCompetitor() {
    return this.api.get(this.collectionName + "_competitor").then((res: any) => {
      return res.data;
    });
  }

  public async correct(model: NewProductMatchingModel) {
    return this.api.post(this.collectionName + "_correct_post", model);
  }
}
