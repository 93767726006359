import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, InputGroup, Modal, Row, Spinner, Table } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { SearchInput } from "../../components/SearchInput";
import SelectSearch from "../../components/SelectSearch";
import TablePagination from "../../components/TablePagination";
import TableRow from "../../components/TableRow";
import { MessageType } from "../../core/config/message-type";
import { useToast } from "../../core/contexts/toast";
import { NewProductMatchingModel } from "../../core/model/new_product_matching";
import { ProductModel } from "../../core/model/product";
import { FilterNewProductMatching, NewProductMatchingRepository } from "../../core/repositories/new_product_matching.repository";
import { similarity } from "../../core/utils/compare-strings";
import { getNameFilter, matchingStatusListWE } from "../../core/utils/functions";
import useTable from "../../core/utils/useTable";
import loadingGif from "../../assets/imagens/loading.gif";

interface Props {
  show: boolean;
  onHide: () => void;
}

var count: number = 0;

export const ModalNewProductMatching: React.FC<Props> = (props) => {
  const initialCbOptions = [
    { id: "A", label: "A", checked: true },
    { id: "B", label: "B", checked: true },
    { id: "C", label: "C", checked: true },
  ];
  const [cbOptions, setCbOptions] = useState(initialCbOptions);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<NewProductMatchingModel[]>([]);
  const [loadingBtnDownload, setLoadingBtnDownload] = useState(false);

  const [search, setSearch] = useState(new FilterNewProductMatching());
  const { toastShow } = useToast();

  const _newProductMatchingRepository = new NewProductMatchingRepository();

  useEffect(() => {
    if (props.show) {
      document.getElementById("search")?.focus();

      setCbOptions(initialCbOptions);
      const newFilter = new FilterNewProductMatching();
      newFilter.curve = "A, B, C";
      newFilter.match_status = 1;
      newFilter.pageSize = 10;
      changeSearch(newFilter);

      loadingList(newFilter);
    }
  }, [props.show]);

  function loadingList(search: FilterNewProductMatching) {
    const _count = count++;
    setLoading(true);

    _newProductMatchingRepository
      .getFilter(search)
      .then((data) => {
        if (_count === count - 1) {
          search.total = data.length ? data[0].total_records : data.length;
          setSearch({ ...search });
          setData(data);
          setLoading(false);
        }
      })
      .catch(() => {
        setData([]);
        setLoading(false);
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });
  }

  // useEffect(() => {
  //   const timeOutId = setTimeout(() => handleSearchName(), 100);
  //   return () => clearTimeout(timeOutId);
  // }, [searchDescribe]);

  // useEffect(() => {
  //   const timeOutId = setTimeout(() => handleSearchId(), 100);
  //   return () => clearTimeout(timeOutId);
  // }, [searchCod]);

  const handleCheckboxChange = (id: string) => {
    const updatedOptions = cbOptions.map((option) => (option.id === id ? { ...option, checked: !option.checked } : option));

    // Verifica se pelo menos uma opção está marcada
    const atLeastOneChecked = updatedOptions.some((option) => option.checked);

    if (atLeastOneChecked) {
      setCbOptions(updatedOptions);

      const selectedValues = updatedOptions
        .filter((option) => option.checked)
        .map((option) => option.id)
        .join(", ");

      search.curve = selectedValues;
      changeSearch(search);
    }
    // else {
    //   alert("Pelo menos uma checkbox precisa estar selecionada!");
    // }
  };

  function changeSort(search: FilterNewProductMatching) {
    setSearch({ ...search });
    loadingList(search);
  }

  function changeSearch(search: FilterNewProductMatching) {
    search.page = 1;
    setSearch({ ...search });
  }

  function getList(search: FilterNewProductMatching) {
    changeSearch(search);
    loadingList(search);
  }

  function download() {
    setLoadingBtnDownload(true);
    _newProductMatchingRepository
      .download(search)
      .then((req) => {
        var blob = new Blob([req.data], {
          type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Product Matching ${new Date().toISOString()}.xlsx`;
        link.click();
        setLoadingBtnDownload(false);
      })
      .catch(() => {
        setLoadingBtnDownload(false);
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });
  }

  return (
    <>
      <Modal centered aria-labelledby="contained-modal-title-vcenter" size="xl" className="modal-product" {...props}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center font-size-16">
            Extração Product Matching
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={4}>
              <SearchInput
                description="Concorrente: Cód. Produto"
                value={search.competitor_product_id}
                onChange={(event) => {
                  search.competitor_product_id = event;
                  changeSearch(search);
                }}
              />
            </Col>

            <Col md={8}>
              <SearchInput
                description="Concorrente: Descrição"
                value={search.competitor_product_description}
                onChange={(event) => {
                  search.competitor_product_description = event;
                  changeSearch(search);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <SelectSearch
                name="Status"
                selectId="id"
                selectName="description"
                required={true}
                dontShowSearch={true}
                value={search.match_status}
                options={matchingStatusListWE}
                onChange={(value) => {
                  search.match_status = value;
                  search.match_status_description = getNameFilter(matchingStatusListWE, value, "id", "description");

                  search.product_code = "";
                  search.category_code = "";

                  changeSearch(search);
                }}
              />
            </Col>

            <Col>
              <Form.Group className={"w-100"}>
                <Form.Label className="font-size-14 relative">Curva ABC</Form.Label>
                <div className="position-relative">
                  {cbOptions.map((option) => (
                    <Form.Check
                      inline
                      type="checkbox"
                      key={option.id}
                      id={"checkbox-" + option.id}
                      name="group1"
                      aria-label={option.label}
                      aria-checked={option.checked}
                      label={option.label}
                      checked={option.checked}
                      onChange={() => handleCheckboxChange(option.id)}
                    />
                  ))}
                </div>
                {/* <small className="text-muted">Filtro Atual: {props.search.curve || "Nenhum selecionado"}</small> */}
              </Form.Group>
            </Col>

            <Col md={3}>
              <div className="d-flex justify-content-end margin-top-32">
                <Button
                  className="ms-1"
                  variant="primary"
                  // disabled={!props.filtro.year_week}
                  onClick={() => {
                    getList(search);
                  }}
                >
                  Buscar
                </Button>
              </div>
            </Col>
          </Row>

          <br />

          {loading ? (
            <div className="height-500">
              <div className="text-center loading align-vertical  ">
                <img className="width-150 height-150" src={loadingGif} alt="" />
              </div>
            </div>
          ) : (
            <div className="height-500">
              <div className="min-height-460">
                <Table bordered hover responsive borderless size="sm" className="mb-0 ">
                  <thead>
                    <tr>
                      <TableRow>Concorrente ID</TableRow>

                      <TableRow>Produto Concorrente</TableRow>

                      <TableRow>Curva ABC</TableRow>

                      <TableRow>Produto Laredo</TableRow>

                      <TableRow>Usuário</TableRow>

                      <TableRow>Data</TableRow>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length === 0 && (
                      <tr className="table-row-header text-center">
                        <td colSpan={8}> Nenhum registro encontrado! </td>
                      </tr>
                    )}
                    {data.map((_, index) => (
                      <tr
                        key={`TableModalNewProductMatching_${index}`}
                        className={_.match_status == 2 ? "bg-lightred" : _.match_status == 1 || _.match_status == 5 ? "bg-ligthgreen" : ""}
                      >
                        {/* Concorrente ID */}
                        <td className="text-center">{_.competitor_id}</td>

                        {/* Produto Concorrente */}
                        <td>
                          <b>{"[" + _.competitor_product_id + "]"}</b> {_.competitor_product_description}
                        </td>

                        {/* Curva */}
                        <td className="text-center">
                          <b>{_.curve ? _.curve : "-"}</b>
                        </td>

                        {/* Produto Laredo */}
                        <td>
                          {_.product_id && <b>{"[" + _.product_id + "]"}</b>} {_.product_description}
                        </td>

                        {/* Usuário */}
                        <td className="text-center relative">{_.loading ? <Spinner animation="grow" size="sm" /> : <label>{_.user_full_name}</label>}</td>

                        {/* Última Atualização */}
                        <td className="text-center relative">
                          {_.loading ? (
                            <Spinner animation="grow" size="sm" />
                          ) : (
                            _.date_update_by_user && <label>{moment.utc(_.date_update_by_user).local().format("DD/MM/YYYY HH:mm")}</label>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div>
                <TablePagination
                  onChangePage={(value) => {
                    search.page = value;
                    changeSort(search);
                  }}
                  currentPage={search.page}
                  pageSize={search.pageSize}
                  count={search.total}
                />
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              props.onHide();
            }}
          >
            Cancelar
          </Button>

          <Button
            style={{ width: "100px" }}
            disabled={loadingBtnDownload}
            variant="success"
            onClick={() => {
              download();
            }}
          >
            {loadingBtnDownload ? <Spinner animation="grow" size="sm" /> : <label>Download</label>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
