import { createContext, useState, useContext, useEffect } from "react";
// import Cookies from 'universal-cookie/es6';
import { BasePriceCatalogModel, BasePriceCatalogModelGranularidade } from "../model/base_price_catalog";
import { FilterBasePriceCatalog, BasePriceCatalogRepository } from "../repositories/base_price_catalog.repository";
import { BasePriceCatalogEditModel } from "../model/base_price_catalog_edit";
import { BasePriceCatalogEditRepository } from "../repositories/base_price_catalog_edit.repository";
import { useAuth } from "./auth";
import { BasePriceCatalogMinimumModel } from "../model/base_price_catalog_minimum";
import { BasePriceCatalogMinimumRepository } from "../repositories/base_price_catalog_minimum.repository";

// O que o context irá passar para o outros componentes
interface BasePriceCatalogInterface {
  lista: BasePriceCatalogModel[] | [];
  salvando: boolean;
  carregando: boolean;
  carregandoFilho: boolean;
  filtroNoContexto: FilterBasePriceCatalog;
  carregarLista(filtro: FilterBasePriceCatalog): Promise<BasePriceCatalogModel[]>;
  adicionarLista(filtro: FilterBasePriceCatalog, index: number): Promise<number | undefined>;
  removerLista(index: number): void;
  // salvarEditados(): Promise<number>;
  limparLista(): void;
  atualizarInterface(): void;
  atualizarUmaLinha(linha: BasePriceCatalogModel, index: number): void;
  salvarLinhaEditada(produto: BasePriceCatalogModel, index: number): Promise<void>;
  salvarLinhaMinima(produto: BasePriceCatalogModel, index: number): Promise<void>;
  atualizarFiltroContexto(filtro: FilterBasePriceCatalog): void;
  setCarregando(carregando: boolean): void;
}

// Como o objeto de context vai iniciar
const BasePriceCatalogContext = createContext<BasePriceCatalogInterface>({} as BasePriceCatalogInterface);

type Props = {
  children?: JSX.Element;
};

// Componente de context que irá por volta de todos os outros componentes
// eslint-disable-next-line
export const BasePriceCatalogProvider: React.FC<Props> = ({ children }) => {
  const [carregando, setCarregando] = useState(false);
  const [salvando, setSalvando] = useState(false);
  const [carregandoFilho, setCarregandoFilho] = useState(false);

  var [lista, setLista] = useState<BasePriceCatalogModel[]>([]);
  const [filtroNoContexto, setFiltroContexto] = useState(new FilterBasePriceCatalog());
  const [contador, setContador] = useState(0);

  const { user } = useAuth();

  const _basePriceCatalog = new BasePriceCatalogRepository();
  const _basePriceCatalogEdit = new BasePriceCatalogEditRepository();
  const _basePriceCatalogMinimum = new BasePriceCatalogMinimumRepository();

  function atualizarUmaLinha(linhaNova: BasePriceCatalogModel, index: number) {
    lista[index] = linhaNova;
    atualizaLista(lista);
  }

  async function carregarLista(filtro: FilterBasePriceCatalog) {
    if (!filtro.year_week) return [];
    const _contador = contador + 1;
    setContador(_contador);
    setCarregando(true);

    const basePriceCatalogReturn = await _basePriceCatalog.getFilter(filtro, "_post");

    if (contador === _contador - 1) {
      //para abrir quando carrega trocar aberto para true
      if (filtro.num_level == 0) {
        basePriceCatalogReturn.map((x) => {
          x.flg_aberto = false;
          x.num_level = 0;
        });
      }

      const lista = [...basePriceCatalogReturn];
      atualizaLista(lista);
      setCarregando(false);

      return [...basePriceCatalogReturn];
    } else {
      return [...lista];
    }
  }
  function BasePriceCatalogModelGranularidadeAux(branch_type: string) {
    var c = [...BasePriceCatalogModelGranularidade.CODIGO];

    // Verifica se o elemento existe na lista antes de remover
    if (branch_type != "Distribuidora") {
      // c = c.filter((x) => x != "mesorregion_code");
      c[4] = c[3];
    }

    return { CODIGO: c };
  }

  function calculaMediaDoNivel(index: number) {
    const produto = lista[index];
    const Granularidade = BasePriceCatalogModelGranularidadeAux(produto.branch_type);

    var medPriceMinimum = 0;
    var medPriceEdit = 0;
    var qts = 0;
    for (let i = 0; i < lista.length; i++) {
      const produtoAux = lista[i];
      if (
        produto.num_level + 1 == produtoAux.num_level ||
        (produto.branch_type != "Distribuidora" && produto.num_level + 2 == produtoAux.num_level && produto.num_level)
      ) {
        var igual = 0;
        for (let j = 0; j < produto.num_level + 1; j++) {
          if ((produtoAux as any)[Granularidade.CODIGO[j]] == (produto as any)[Granularidade.CODIGO[j]]) {
            igual++;
          }
        }
        if (igual > produto.num_level && produtoAux.price_edit) {
          medPriceEdit += produtoAux.price_edit * produtoAux.quantity;
          qts += produtoAux.quantity;
        }
      }
    }
    medPriceEdit = qts > 0 ? medPriceEdit / qts : 0;

    return { medPriceEdit, qts };
  }

  function calculaMediaDoNivelMinimo(index: number) {
    const produto = lista[index];
    const Granularidade = BasePriceCatalogModelGranularidadeAux(produto.branch_type);

    var medPriceMinimum = 0;
    var qts = 0;
    for (let i = 0; i < lista.length; i++) {
      const produtoAux = lista[i];
      if (
        produto.num_level + 1 == produtoAux.num_level ||
        (produto.branch_type != "Distribuidora" && produto.num_level + 2 == produtoAux.num_level && produto.num_level)
      ) {
        var igual = 0;
        for (let j = 0; j < produto.num_level + 1; j++) {
          if ((produtoAux as any)[Granularidade.CODIGO[j]] == (produto as any)[Granularidade.CODIGO[j]]) {
            igual++;
          }
        }
        if (igual > produto.num_level && produtoAux.price_minimum) {
          medPriceMinimum += produtoAux.price_minimum * produtoAux.quantity;
          qts += produtoAux.quantity;
        }
      }
    }
    medPriceMinimum = qts > 0 ? medPriceMinimum / qts : 0;

    return { medPriceMinimum, qts };
  }

  async function atualizaRelacionados(index: number, relacionado = false) {
    const produto = lista[index];

    const Granularidade = BasePriceCatalogModelGranularidadeAux(produto.branch_type);

    for (let i = index - 1; i >= 0; i--) {
      var igual = true;
      const produtoAux = lista[i];

      for (let j = 0; j <= produtoAux.num_level && igual; j++) {


        if ((produtoAux as any)[Granularidade.CODIGO[j]] != (produto as any)[Granularidade.CODIGO[j]]) {
          igual = false;
        }
        if (produtoAux.subcategory_priority != produto.subcategory_priority && i > 2) {
          igual = false;
        }
      }
      if (igual) {
        const calculo = calculaMediaDoNivel(i);
        // produtoAux.price_edit = -999999999;
        produtoAux.price_edit = calculo.medPriceEdit;
        produtoAux.quantity = calculo.qts || 1;
      }
    }
    atualizarInterface();
  }

  async function atualizaRelacionadosMinimo(index: number, relacionado = false) {
    const produto = lista[index];

    const Granularidade = BasePriceCatalogModelGranularidadeAux(produto.branch_type);

    for (let i = index - 1; i >= 0; i--) {
      var igual = true;
      const produtoAux = lista[i];
      for (let j = 0; j <= produtoAux.num_level && igual; j++) {
        if ((produtoAux as any)[Granularidade.CODIGO[j]] != (produto as any)[Granularidade.CODIGO[j]]) {
          igual = false;
        }
        if (produtoAux.subcategory_priority != produto.subcategory_priority && i > 2) {
          igual = false;
        }
      }
      if (igual) {
        const calculo = calculaMediaDoNivelMinimo(i);
        // produtoAux.price_minimum = -999999999;
        produtoAux.price_minimum = calculo.medPriceMinimum;
        produtoAux.quantity = calculo.qts || 1;
      }
    }
    atualizarInterface();
  }

  function carregarItensAbaixo(index: number) {
    const produto = lista[index];
    // if (produto.flg_aberto) {
    const nivel = produto.num_level;
    var indexFinal = index + 1;
    // carrega nivel abaixo do atual
    while (indexFinal < lista.length && nivel < lista[indexFinal].num_level) {
      const produtoAux = lista[indexFinal];
      produtoAux.price_edit = produto.price_edit || 0;
      produtoAux.is_accepted = produto.is_accepted;
      produtoAux.is_automated = produto.is_automated;
      indexFinal++;
    }
    // }
    atualizarInterface();
  }

  function carregarItensAbaixoMinimo(index: number) {
    const produto = lista[index];
    // if (produto.flg_aberto) {
    const nivel = produto.num_level;
    var indexFinal = index + 1;
    // carrega nivel abaixo do atual
    while (indexFinal < lista.length && nivel < lista[indexFinal].num_level) {
      const produtoAux = lista[indexFinal];
      produtoAux.price_minimum = produto.price_minimum || 0;
      indexFinal++;
    }
    // }
    atualizarInterface();
  }

  function verificarIrmaos(index: number) {
    var is_automated = 0;
    var is_accepted = 0;
    var qts = 0;
    //verificar todos os elementos do mesmo nivel
    const produto = lista[index];
    const Granularidade = BasePriceCatalogModelGranularidadeAux(produto.branch_type);

    for (let i = 0; i < lista.length; i++) {
      var igual = true;
      const produtoAux = lista[i];
      if (produtoAux.num_level == produto.num_level) {
        for (let j = 0; j <= produto.num_level - 1 && igual; j++) {
          if ((produtoAux as any)[Granularidade.CODIGO[j]] != (produto as any)[Granularidade.CODIGO[j]]) {
            igual = false;
          }
          if (produtoAux.subcategory_priority != produto.subcategory_priority && i > 2) {
            igual = false;
          }
        }
        if (igual) {
          qts++;
          if (produtoAux.is_automated) {
            is_automated++;
          }
          if (produtoAux.is_accepted) {
            is_accepted++;
          }
        }
      }
    }
    //encontrar o nivel acima
    for (let i = index - 1; i >= 0; i--) {
      var igual = true;
      const produtoAux = lista[i];
      if (produtoAux.num_level != produto.num_level) {
        for (let j = 0; j <= produto.num_level - 1 && igual; j++) {
          if ((produtoAux as any)[Granularidade.CODIGO[j]] != (produto as any)[Granularidade.CODIGO[j]]) {
            igual = false;
          }
          if (produtoAux.subcategory_priority != produto.subcategory_priority && i > 2) {
            igual = false;
          }
        }
        if (igual) {
          produtoAux.is_accepted = is_accepted == qts;
          produtoAux.is_automated = is_automated == qts;
        }
      }
    }
  }

  function verificarIrmaosMinimo(index: number) {
    var qts = 0;
    //verificar todos os elementos do mesmo nivel
    const produto = lista[index];
    const Granularidade = BasePriceCatalogModelGranularidadeAux(produto.branch_type);

    for (let i = 0; i < lista.length; i++) {
      var igual = true;
      const produtoAux = lista[i];
      if (produtoAux.num_level == produto.num_level) {
        for (let j = 0; j <= produto.num_level - 1 && igual; j++) {
          if ((produtoAux as any)[Granularidade.CODIGO[j]] != (produto as any)[Granularidade.CODIGO[j]]) {
            igual = false;
          }
          if (produtoAux.subcategory_priority != produto.subcategory_priority && i > 2) {
            igual = false;
          }
        }
        if (igual) {
          qts++;
        }
      }
    }
    //encontrar o nivel acima
    for (let i = index - 1; i >= 0; i--) {
      var igual = true;
      const produtoAux = lista[i];
      if (produtoAux.num_level != produto.num_level) {
        for (let j = 0; j <= produto.num_level - 1 && igual; j++) {
          if ((produtoAux as any)[Granularidade.CODIGO[j]] != (produto as any)[Granularidade.CODIGO[j]]) {
            igual = false;
          }
          if (produtoAux.subcategory_priority != produto.subcategory_priority && i > 2) {
            igual = false;
          }
        }
        if (igual) {
        }
      }
    }
  }

  async function salvarLinhaEditada(produto: BasePriceCatalogModel, index: number) {
    setSalvando(true);

    const item = new BasePriceCatalogEditModel();

    item.num_level = produto.num_level;
    item.branch_type = produto.branch_type;
    item.branch_code = produto.branch_code;
    item.department_code = produto.department_code;
    item.section_code = produto.section_code;
    item.category_code = produto.category_code;
    item.subcategory_code = produto.subcategory_code;
    item.subcategory_priority = produto.subcategory_priority;
    item.mesorregion_code = produto.mesorregion_code;
    item.product_code = produto.product_code;
    item.year_week = filtroNoContexto.year_week;
    item.price_edit = produto.price_edit;
    item.is_automated = produto.is_automated;
    item.is_accepted = produto.is_accepted;
    item.user_id = user?.id;

    item.filter_mesorregion = filtroNoContexto.mesorregion_code ? filtroNoContexto.mesorregion_code : "";
    item.filter_product = filtroNoContexto.product_code ? filtroNoContexto.product_code : "";

    lista[index].flg_salvando = true;
    lista[index].quantity = produto.quantity;
    atualizaLista(lista);

    try {
      await _basePriceCatalogEdit.add(item);
      atualizaRelacionados(index);
      carregarItensAbaixo(index);
      if (produto.num_level == BasePriceCatalogModelGranularidade.CODIGO.length - 1) {
        verificarIrmaos(index);
      }
      lista[index].price_edit = produto.price_edit;
      lista[index].edit_price_save = produto.edit_price_save;
    } catch (error) {
      throw error;
    } finally {
      lista[index].flg_salvando = false;
      atualizaLista(lista);
      setSalvando(false);
    }
  }

  async function salvarLinhaMinima(produto: BasePriceCatalogModel, index: number) {
    setSalvando(true);

    const item = new BasePriceCatalogMinimumModel();

    item.num_level = produto.num_level;
    item.branch_type = produto.branch_type;
    item.subcategory_priority = produto.subcategory_priority;
    item.branch_code = produto.branch_code;
    item.department_code = produto.department_code;
    item.section_code = produto.section_code;
    item.category_code = produto.category_code;
    item.subcategory_code = produto.subcategory_code;
    item.mesorregion_code = produto.mesorregion_code;
    item.product_code = produto.product_code;
    item.year_week = filtroNoContexto.year_week;
    item.price_minimum = produto.price_minimum;
    item.user_id = user?.id;

    item.filter_mesorregion = filtroNoContexto.mesorregion_code ? filtroNoContexto.mesorregion_code : "";
    item.filter_product = filtroNoContexto.product_code ? filtroNoContexto.product_code : "";

    lista[index].flg_salvando = true;
    lista[index].quantity = produto.quantity;

    atualizaLista(lista);
    try {
      //TODO
      await _basePriceCatalogMinimum.add(item);
      atualizaRelacionadosMinimo(index);
      carregarItensAbaixoMinimo(index);
      if (produto.num_level == BasePriceCatalogModelGranularidade.CODIGO.length - 1) {
        verificarIrmaosMinimo(index);
      }
      lista[index].price_minimum = produto.price_minimum;
      lista[index].minimum_price_save = produto.minimum_price_save;
    } catch (error) {
      throw error;
    } finally {
      lista[index].flg_salvando = false;
      atualizaLista(lista);
      setSalvando(false);
    }
  }

  function removerLista(index: number) {
    lista[index].flg_aberto = false;
    const nivel = lista[index].num_level;
    var indexFinal = index + 1;

    while (indexFinal < lista.length && nivel < lista[indexFinal].num_level) {
      const element = lista[indexFinal];
      element.oculta = true;
      element.flg_aberto = false;
      indexFinal++;
    }
    atualizaLista(lista);
  }

  async function adicionarLista(filtroGeral: FilterBasePriceCatalog, index: number) {
    try {
      const linha = lista[index];
      setCarregandoFilho(true);
      linha.flg_aberto = true;
      if (linha.sons > 0) {
        var nivel = lista[index].num_level;
        if (linha.num_level == 3 && linha.branch_type != "Distribuidora") nivel++;

        var indexFinal = index + 1;

        while (indexFinal < lista.length && nivel < lista[indexFinal].num_level) {
          const element = lista[indexFinal];
          // para mostrar somente os que estão um nível abaixo
          element.oculta = nivel + 1 != lista[indexFinal].num_level;
          indexFinal++;
        }
        atualizaLista(lista);
      } else {
        const filtro = { ...filtroGeral };

        if (linha.num_level == 3 && linha.branch_type != "Distribuidora") {
          filtro.num_level = linha.num_level + 2;
        } else {
          filtro.num_level = linha.num_level + 1;
        }

        for (let index = 0; index < filtro.num_level; index++) {
          (filtro as any)[BasePriceCatalogModelGranularidade.CODIGO[index]] = (linha as any)[BasePriceCatalogModelGranularidade.CODIGO[index]];
        }

        (filtro as any).subcategory_priority = linha.subcategory_priority;
        const response = await _basePriceCatalog.getFilter(filtro, "_post");
        response.map((x) => {
          if (linha.num_level == 3 && linha.branch_type != "Distribuidora") {
            x.num_level = linha.num_level + 2;
          } else {
            x.num_level = linha.num_level + 1;
          }
        });
        linha.sons = response.length;
        lista.splice(index + 1, 0, ...response);
        atualizaLista(lista);
      }
      setCarregandoFilho(false);
      return linha.sons;
    } catch (error) {
      setCarregandoFilho(false);
      return Promise.reject(error);
    }
  }

  function limparLista() {
    atualizaLista([]);
  }

  function atualizarInterface() {
    setLista([...lista]);
  }

  function atualizaLista(nova: BasePriceCatalogModel[]) {
    setLista(nova);
    lista = nova;
  }

  function atualizarFiltroContexto(filtro: FilterBasePriceCatalog) {
    setFiltroContexto({ ...filtro });
  }

  return (
    // eslint-disable-next-line
    <BasePriceCatalogContext.Provider
      value={{
        lista,
        salvando,
        carregando,
        carregandoFilho,
        filtroNoContexto,
        carregarLista,
        adicionarLista,
        removerLista,
        limparLista,
        atualizarInterface,
        atualizarUmaLinha,
        salvarLinhaEditada,
        salvarLinhaMinima,
        atualizarFiltroContexto,
        setCarregando,
      }}
    >
      {children}
    </BasePriceCatalogContext.Provider>
  );
};

export function basePriceCatalogContext() {
  const context = useContext(BasePriceCatalogContext);

  return context;
}
