export enum CollecionName {
  PRODUCT = "product",
  PRODUCT_DEPARTMENT = "product_department",
  PRODUCT_SECTION = "product_section",
  PRODUCT_CATEGORY = "product_category",
  PRODUCT_SUBCATEGORY = "product_subcategory",
  PRODUCT_COMPETITOR = "product_competitor",
  PRODUCT_MATCHING = "product_matching",
  PRODUCT_MATCHING_MANUAL = "product_matching_manual",
  MATCHING_VALIDATIONS = "matching_validations",
  PURCHASE_ORDER = "purchase_order",
  NEW_PRODUCT_MATCHING = "new_product_matching",

  BRANCH = "branch",
  PRICE_TABLE = "price_table",

  COMPETITOR = "competitor",

  CUSTOMER = "customer",
  CUSTOMER_CITY = "customer_city",
  CUSTOMER_CLUSTER = "customer_cluster",
  CUSTOMER_SEGMENTATION = "customer_segmentation",
  CNPJ_HASH = "cnpj_hash",
  BASE_PRICE_CATALOG = "base_price_catalog",
  BASE_PRICE_CATALOG_EDIT = "base_price_catalog_edit",
  BASE_PRICE_CATALOG_MINIMUM = "base_price_catalog_minimum",
  LOG_API = "log_api",

  USER = "user",
  ACTIVE_CLUSTER = "chart_active_cluster",
  CHART_COHORT = "chart_cohort",
  DEMAND_FORECAST = "demand_forecast",
  DEMAND_VARIABILITY = "demand_variability",
  MESORREGION = "mesorregion",

  MOMENT_FORMAT = "DD/MM/YYYY",
}
