export function convertArrayToCsvText(array: Array<string>) {
  return array.map((d: any) => `'${d}'`).join(",");
}
export function validarCNPJ(cnpj: string) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  var tamanho = cnpj.length - 2;
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (i = tamanho; i >= 1; i--) {
    soma += Number.parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != Number.parseInt(digitos.charAt(0))) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += Number.parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != Number.parseInt(digitos.charAt(1))) return false;

  return true;
}
export function CloneObj(item: any) {
  return JSON.parse(JSON.stringify(item));
}
export function formatDecimal(item?: any, money = false, currency = "BRL") {
  if (item) {
    item = Number.parseFloat(item);
    if (item === 0) return "-";

    if (money) {
      return item.toLocaleString("pt-BR", {
        style: "currency",
        currency: currency,
      });
    } else {
      return item.toLocaleString("pt-BR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    }
  } else {
    return "-";
  }
}

export function CalcMargin(item1?: any, item2?: any) {
  if (item1 && item2) {
    item1 = Number.parseFloat(item1);
    item2 = Number.parseFloat(item2);
    return (item1 - item2) / item1;
  } else {
    return 0;
  }
}

export function CalcTrend(item1?: any, item2?: any) {
  if (item1 && item2) {
    item1 = Number.parseFloat(item1);
    item2 = Number.parseFloat(item2);
    return (item1 - item2) / item2;
  } else {
    return 0;
  }
}

export function getNameFilter(list: any, value: string, id: string = "id", name: string = "description") {
  value = value.toString();
  return list
    .filter((x: any) => value.split(",").find((y: any) => y == x[id]))
    ?.map((x: any) => x[name])
    .join(", ");
}

export function formatPercent(item?: any, precision = 2) {
  if (item != null && item != undefined) {
    item = Number.parseFloat(item) * 100;
    return (
      item.toLocaleString("en-US", {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      }) + "%"
    );
  } else {
    return "-";
  }
}
export const matchingRatingList = [
  { id: 5, description: "Excelente" },
  { id: 4, description: "Muito Bom" },
  { id: 3, description: "Bom" },
  { id: 2, description: "Regular" },
  { id: 1, description: "Razoável" },
];

export function get_matching_rating_description(matching_rating: number) {
  return matchingRatingList.find((x) => x.id == matching_rating)?.description;
}

export const competitorTypeList = [
  { id: "", description: "Todos" },
  { id: "other", description: "Outros" },
  { id: "GV", description: "GV - Grande Varejista" },
  { id: "CD", description: "CD - Concorrente Direto" },
  { id: "IL", description: "IL - Indústria Local" },
];

export const matchingStatusList = [
  { id: "", description: "Todos" },
  { id: 0, description: "Sem Casamento" },
  { id: 1, description: "Confirmado" },
  { id: 2, description: "Erros" },
  // { id: 3, description: "Duplicados" },
];

export const matchingStatusListWE = [
  { id: "", description: "Todos" },
  { id: 0, description: "Sem Casamento" },
  { id: 1, description: "Confirmado" },
];

export function get_matching_status_description(item: number) {
  return matchingStatusList.filter((x) => x.id !== "").find((x) => x.id == item)?.description;
}

/**
 * Gera um número aleatório entre min e max (inclusive).
 *
 * @param {number} min - O valor mínimo do intervalo.
 * @param {number} max - O valor máximo do intervalo.
 * @returns {number} Um número aleatório entre min e max.
 */
export function getRandomNumberInRange(min: number, max: number) {
  // Certifique-se de que min e max são números válidos
  if (typeof min !== "number" || typeof max !== "number") {
    throw new Error("Os parâmetros min e max devem ser números.");
  }

  // Certifique-se de que min não seja maior que max
  if (min > max) {
    throw new Error("O valor de min não pode ser maior que o valor de max.");
  }

  return Math.random() * (max - min) + min;
}

export function colors_default() {
  return ["#2C373D", "#9A3434", "#DD4740", "#9b51e0", "#8ed1fc", "#00d084", "#7bdcb5", "#fcb900", "#ff6900", "#cf2e2e", "#999999"];
}

export function colors_plotly() {
  return [
    "#636EFA",
    "#EF553B",
    "#00CC96",
    "#AB63FA",
    "#FFA15A",
    "#19D3F3",
    "#FF6692",
    "#B6E880",
    "#FF97FF",
    "#FECB52",
    "#2E91E5",
    "#E15F99",
    "#1CA71C",
    "#FB0D0D",
    "#DA16FF",
    "#222A2A",
    "#B68100",
    "#750D86",
    "#EB663B",
    "#511CFB",
    "#00A08B",
    "#FB00D1",
    "#FC0080",
    "#B2828D",
    "#6C7C32",
    "#778AAE",
    "#862A16",
    "#A777F1",
    "#620042",
    "#1616A7",
    "#DA60CA",
    "#6C4516",
    "#0D2A63",
    "#AF0038",
    "#FD3216",
    "#00FE35",
    "#6A76FC",
    "#FED4C4",
    "#FE00CE",
    "#0DF9FF",
    "#F6F926",
    "#FF9616",
    "#479B55",
    "#EEA6FB",
    "#DC587D",
    "#D626FF",
    "#6E899C",
    "#00B5F7",
    "#B68E00",
    "#C9FBE5",
    "#FF0092",
    "#22FFA7",
    "#E3EE9E",
    "#86CE00",
    "#BC7196",
    "#7E7DCD",
    "#FC6955",
    "#E48F72",
  ];
}

export function atualizaTamanhoTabela() {
  const elementoTabela = document.getElementById("tabela")?.children[0] as HTMLElement;
  if (elementoTabela) {
    const alturaTabela = `calc(100vh - ${elementoTabela.getBoundingClientRect().top}px - 0px)`;
    elementoTabela.style.height = alturaTabela;
  }
}
