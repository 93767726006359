import React from "react";

import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../core/contexts/auth";
import { UserType } from "../core/config/user-type";
import { useEffect } from "react";

const RequireAuth = ({ allowedRoles }: { allowedRoles: any }) => {
  const { getUser, getUserCookie } = useAuth();
  const location = useLocation();

  return allowedRoles.includes(getUser()?.user_type) ? <Outlet /> : <Navigate to="/page-404" state={{ from: location }} replace />;
};

export default RequireAuth;

export function check_type(type: UserType) {
  const { getUser } = useAuth();
  const user_type = getUser()?.user_type || 1000;
  return user_type <= type;
}
