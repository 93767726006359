import * as React from "react";
import { useEffect, useState } from "react";
import { Container, Card, Button, Spinner } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import { Filter } from "./filter";
import { MessageType } from "../../core/config/message-type";
import { ProductModel } from "../../core/model/product";
import { ProductRepository } from "../../core/repositories/product.repository";
import { FaDownload } from "react-icons/fa";
import loadingGif from "../../assets/imagens/loading.gif";
import "./index.scss";
import { FilterNewProductMatching, NewProductMatchingRepository } from "../../core/repositories/new_product_matching.repository";
import { NewProductMatchingModel } from "../../core/model/new_product_matching";
import { TableNewProductMatching } from "./table";
import { ModalNewProductMatching } from "./modal";

var count: number = 0;

export const NewProductMatching: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const { toastShow } = useToast();
  const [search, setSearch] = useState(new FilterNewProductMatching());
  const [productList, setProductList] = useState<ProductModel[]>([]);
  const [data, setData] = useState<NewProductMatchingModel[]>([]);
  const [modalShowEdit, setModalShowEdit] = React.useState(false);

  const _newProductMatchingRepository = new NewProductMatchingRepository();

  useEffect(() => {
    getList(search);
    new ProductRepository()
      .getFilter({}, "_post")
      .then((res) => {
        setProductList(res);
      })
      .catch((error) => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });
    // _newProductMatchingRepository.getLastUpdate().then((res) => {
    //   setLastUpdate(res);
    // });
  }, []);

  function changeSearch(search: FilterNewProductMatching) {
    search.page = 1;
    setSearch({ ...search });
  }

  function getList(search: FilterNewProductMatching) {
    changeSearch(search);
    loadingList(search);
  }

  function changeSort(search: FilterNewProductMatching) {
    setSearch({ ...search });
    loadingList(search);
  }

  function loadingList(search: FilterNewProductMatching) {
    const _count = count++;
    setLoading(true);

    _newProductMatchingRepository
      .getFilter(search)
      .then((data) => {
        if (_count === count - 1) {
          search.total = data.length ? data[0].total_records : data.length;
          setSearch({ ...search });
          setData(data);
          setLoading(false);
        }
      })
      .catch(() => {
        setData([]);
        setLoading(false);
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });
  }

  function changeData(item: NewProductMatchingModel, index: number) {
    data[index] = item;
    setData([...data]);
  }

  function removeData(index: number) {
    data.splice(index, 1);
    setData([...data]);
  }

  function onDownload() {
    setModalShowEdit(true);
  }

  return (
    <>
      <Container className="NewProductMatching max-98">
        <Filter search={search} onChangeFilter={changeSearch} onSearch={getList} onDownload={onDownload} />
        {/* <Button className="download-bnt" disabled={loadingBtnDownload} onClick={download} variant="secondary">
				{loadingBtnDownload ? <Spinner size="sm" animation="border" role="status" /> : <FaDownload />}
			</Button> */}
        <Card className="card-d">
          <Card.Body
          // style={{
          //   minHeight: "500px", // Altura mínima garantida
          //   overflow: "visible", // Permite que o conteúdo transborde
          //   position: "relative", // Contexto para posicionamento absoluto
          // }}
          >
            {loading ? (
              <div className="height-500">
                <div className="text-center loading align-vertical  ">
                  <img className="width-150 height-150" src={loadingGif} alt="" />
                </div>
              </div>
            ) : (
              <TableNewProductMatching
                search={search}
                productList={productList}
                onChangeSort={changeSort}
                onChangeData={changeData}
                onRemoveData={removeData}
                // lastUpdate={lastUpdate}
                data={data}
              />
            )}
          </Card.Body>
        </Card>
      </Container>

      <ModalNewProductMatching
        show={modalShowEdit}
        onHide={() => {
          setModalShowEdit(false);
        }}
      />
    </>
  );
};
